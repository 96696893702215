/*!

=========================================================
* Material Dashboard PRO React - v1.8.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// variables and mixins
@import "material-dashboard-pro-react/variables";
@import "material-dashboard-pro-react/mixins";
@import "material-dashboard-pro-react/shadows";

// plugin css
@import "material-dashboard-pro-react/plugins/plugin-nouislider";
@import "material-dashboard-pro-react/plugins/plugin-perfect-scrollbar";
@import "material-dashboard-pro-react/plugins/plugin-react-datetime";
@import "material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert";
@import "material-dashboard-pro-react/plugins/plugin-react-chartist";
@import "material-dashboard-pro-react/plugins/plugin-react-big-calendar";
@import "material-dashboard-pro-react/plugins/plugin-react-jvectormap";
@import "material-dashboard-pro-react/plugins/plugin-react-table";
@import "material-dashboard-pro-react/plugins/plugin-react-tagsinput";

// Core CSS
@import "material-dashboard-pro-react/misc";
@import "material-dashboard-pro-react/fileupload";
@import "material-dashboard-pro-react/fixed-plugin";

.frame {
  overflow-y: auto;
  // border: 1px solid black;
  height: 700px;
  // width: 300px;
  line-height: 1em;
  padding: 10px;
}
.chat-frame {
  overflow-y: auto;
  // border: 1px solid black;
  height: 420px;
  // width: 300px;
  line-height: 1em;
  //padding: 10px;
}
.shift-frame {
  overflow-y: auto;
  // border: 1px solid black;
  height: 350px;
  // width: 300px;
  line-height: 1em;
  //padding: 10px;
}
.scrolls {
  overflow-x: scroll;
  overflow-y: hidden;
  width: "50vh";
  height: 200px;
  white-space: nowrap;
}
.imageDiv {
  box-shadow: 1px 1px 10px #999;
  margin: 2px;
  cursor: pointer;
  height: 200px;
  width: 200px;
  display: inline-block;
  *display: inline; /* For IE7*/
  *zoom: 1; /* For IE7*/
  vertical-align: top;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.request_card:hover {
  cursor: pointer;
}

.chat-frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.chat-frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.chat-frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.chat-frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.rce-mbox-photo--img img {
  height: 200px !important;
  cursor: pointer;
}
.rce-mbox-photo--img {
  max-width: 400px;
}
.rce-mbox-photo--img__block {
  background-color: transparent !important;
}
